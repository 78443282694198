/**
 * !
 * Please do not put the LOCAL URL with the server one anymore, instead execute
 * npm run cracto start && NODE_ENV=production
 */

// TODO change this as an env variable
export const S3_BUCKET_URL = 'https://pagoabogado-dev.s3.awamzonaws.com/';

// TODO change this as an env variable
export const newServer =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://api.noahsarkpets.net/api/'
    : 'http://localhost:6005/api/';

export const processorsURL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://secure.ipaymentsolutions.co'
    : process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://sandbox.ipaymentsolutions.co'
    : 'http://127.0.0.1:6001';

export const usioURL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://secure.ipaymentsolutions.co'
    : process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://sandbox.ipaymentsolutions.co'
    : 'http://127.0.0.1:6003';

export const titles = [
  'CEO',
  'COO',
  'CFO',
  'CTO',
  'Director',
  'Supervisor',
  'Analyst',
  'Manager',
  'Other',
  'Owner',
  'Managing Attorney',
  'Managing Partner',
  'Legal Manager',
  'Treasury Officer',
  'Non Legal Manager',
];

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const formcustomer = {
  firstName: { message: 'Please enter your First Name' },
  lastName: { message: 'Please enter your Last Name' },
  email: { message: 'Please provide your Email' },
  businessName: { message: 'Please specify your Business Name' },
  ssn: { message: 'Please provide your Social Security Number' },
  businessClassification: { message: 'Please select your Business Classification' },
  industry: { message: 'Please select your Industry' },
  address: { message: 'Please enter your Address' },
  city: { message: 'Please enter your City' },
  state: { message: 'Please select your State' },
  zip: { message: 'Please enter a 5-digit Postal Code' },
  dateOfBirth: { message: 'Please provide your Date of Birth' },
  doingBusinessAs: { message: 'Please specify your Doing Business As name' },
};

export const formusio = {
  firstName: { message: 'Please enter your First Name' },
  lastName: { message: 'Please enter your Last Name' },
  email: { message: 'Please provide your Email' },
  cardnumber: { message: 'Please enter your Card Number' }, //TODO Porque ocupamos Card details aqui ? por si cobramos alguna subscripcion?
  cardtype: { message: 'Please select your Card Type' }, //TODO Porque ocupamos Card details aqui ? por si cobramos alguna subscripcion?
  cvv: { message: 'Please enter the CVV' }, //TODO Porque ocupamos Card details aqui ? por si cobramos alguna subscripcion?
  expdate: { message: 'Please provide the Expiry Date' }, //TODO Porque ocupamos Card details aqui ? por si cobramos alguna subscripcion?
  address: { message: 'Please enter your Address' },
  city: { message: 'Please enter your City' },
  state: { message: 'Please select your State' },
  zip: { message: 'Please enter a 5-digit Postal Code' },
  amount: { message: 'Please specify the Amount' }, //TODO Why do we need this one ?
  province: { message: 'Please enter your Province' }, //TODO What is this ? Province??
  country: { message: 'Please enter your Country' },
  phone: { message: 'Please provide your Phone Number' },
  title: { message: 'Please specify your Owner Title' },
  ssn: { message: 'Please provide your Social Security Number' },
  dbaname: { message: 'Please specify your Doing Business As (DBA) Name' },
  legalname: { message: 'Please provide your Legal Name' },
  dob: { message: "Please provide your Owner's Date of Birth" },
  ownershipPercent: { message: 'Please specify the Ownership Percentage' },
};

export const formcustomercontroller = {
  nameController: { message: 'Please enter the First Name of the Controller' },
  lastNameController: { message: 'Please enter the Last Name of the Controller' },
  titleContoller: { message: 'CEO/COO/President' },
  ssn: { message: 'Please provide the Social Security Number of the Controller' },
  address: { message: 'Please enter the Address of the Controller' },
  city: { message: 'Please enter the City of the Controller' },
  state: { message: 'Please select the State of the Controller' },
  zip: { message: 'Please enter a 5-digit Postal Code for the Controller' },
};

export const formcustomerbeneficial = {
  firstName: { message: 'Please enter the First Name of the Beneficiary' },
  lastName: { message: 'Please enter the Last Name of the Beneficiary' },
  dateOfBirth: { message: 'Please provide the Date of Birth of the Beneficiary' },
  numberPassort: { message: 'Please enter the Passport Number of the Beneficiary' },
  ssn: { message: 'Please provide the Social Security Number of the Beneficiary' },
  countryPassport: { message: 'Please specify the Country of the Passport' },
  address: { message: 'Please enter the Address of the Beneficiary' },
  city: { message: 'Please enter the City of the Beneficiary' },
  state: { message: 'Please select the State of the Beneficiary' },
  zip: { message: 'Please enter a 5-digit Postal Code for the Beneficiary' },
  percent: { message: 'Please specify the Ownership Percentage for the Beneficiary' },
};

export const newBusinessTypes = [
  // 'Partnership Public',
  'C-Corp Private',
  'S-Corp Private',
  // 'Sole Prop',
  // 'Partnership Private',
  'LLC Private',
  // 'Not For Profit',
  'C-Corp Public',
  'S-Corp Public',
  // 'Government Agency',
  'LLC Public',
];

export const statesAbbreviations = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const category_states = ['Active', 'Inactive'];

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const convertCurrentToNumber = (current: string) => {
  return Number(current.replace(/[^0-9\.]/g, ''));
};

export const getGuid = (guid: any) => {
  return process.env.REACT_APP_ENVIRONMENT === 'production'
    ? `https://enroll.usiopay.com/click_to_agree.aspx?id=${guid}&embedded=TRUE`
    : `https://devenroll.usiopay.com/click_to_agree.aspx?id=${guid}&embedded=TRUE`;
};

export const WebhookURL = 'https://api.pagoabogado.com/webhook';

export const ipayNet = Object.freeze({
  X_API_KEY: '3BUiRAivqzaYhoPBO4s0G6EuNhMoqD6p2FiXOSWa',
  project: 'a423a585-68f1-4b64-8294-a23bccf707eb',
  apiToken: 'd9b6c2d35fd963f9e79b81797dc9b23afac342a36378a1e33407840bbb1a8a0e',
});

export const descriptionBusiness = 'Legal Billing Software';

export const bussinessClasification = 'Services - other';
export const industryClasification = 'Legal services and attorneys';
