import * as React from 'react';
import { useState, useEffect } from 'react';
import GjsEditor from '@grapesjs/react';
import grapesjs from 'grapesjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './GrapesJSEditor.css';
import { newServer } from 'constants/constants';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

interface PageConfig {
  id: string;
  name: string;
  html: string;
  css: string;
}

export default function App() {
  const [editor, setEditor] = useState<any>(null);
  const [pages, setPages] = useState<PageConfig[]>([]); // Inicializamos vacío
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const [selectedPageForRename, setSelectedPageForRename] = useState<string | null>(null);

  // Cargar las páginas desde el backend
  const fetchPages = async () => {
    try {
      const response = await fetch(`${newServer}landing-page/getAll`);
      const data = await response.json();
      setPages(data); // Actualizamos el estado con las páginas obtenidas
      if (data.length > 0) {
        setSelectedPageId(data[0].id); // Seleccionamos la primera página por defecto
      }
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  // Ejecutar al montar el componente
  useEffect(() => {
    fetchPages();
  }, []);

  // Cargar la página seleccionada en el editor
  useEffect(() => {
    if (editor && selectedPageId) {
      const selectedPage = pages.find((page) => page.id === selectedPageId);
      if (selectedPage) {
        editor.setComponents(selectedPage.html); // Asigna el HTML al editor
        editor.setStyle(selectedPage.css); // Asigna el CSS al editor
      }
    }
  }, [selectedPageId, editor, pages]); // Ejecuta cada vez que cambia la página seleccionada o el editor

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, pageId: string) => {
    setSelectedPageId(pageId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = (pageId: string) => {
    const page = pages.find((p) => p.id === pageId);
    setNewPageName(page?.name || '');
    setSelectedPageForRename(pageId);
    setOpenRenameModal(true);
    handleMenuClose();
  };

  const handleRename = () => {
    if (selectedPageForRename) {
      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === selectedPageForRename ? { ...page, name: newPageName } : page
        )
      );
      setOpenRenameModal(false);
    }
  };

  const handleDeleteClick = (pageId: string) => {
    if (pages.length > 1) {
      deletePage(pageId);
      setPages(pages.filter((page) => page.id !== pageId));
      handleMenuClose();
      setSelectedPageId(pages[0].id); // Cambiar a la primera página disponible
    }
  };

  const onEditor = (editor: any) => {
    console.log('Editor loaded');
    setEditor(editor);

    // Cargar la primera página al inicio
    if (pages.length > 0) {
      const initialPage = pages[0];
      editor.setComponents(initialPage.html);
      editor.setStyle(initialPage.css);
    }
  };

  const addPage = () => {
    const pageCount = pages.length;
    const newPage = {
      id: `page-${pageCount + 1}`,
      name: `Page ${pageCount + 1}`,
      html: `<div class="min-h-screen bg-white">
        <h1 class="text-4xl font-bold text-center py-8">New Page ${pageCount + 1}</h1>
      </div>`,
      css: '',
    };
    setPages([...pages, newPage]);
  };

  const saveEdition = () => {
    if (editor) {
      const updatedPages = pages.map((page) =>
        page.id === selectedPageId
          ? {
              ...page,
              html: editor.getHtml(),
              css: editor.getCss(),
            }
          : page
      );
      setPages(updatedPages);

      // Enviar las páginas al backend para guardarlas
      fetch(`${newServer}landing-page/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pages: updatedPages }),
      })
        .then((response) => response.text())
        .then((data) => {
          console.log(data); // Muestra la respuesta del servidor
        })
        .catch((error) => {
          console.error('Error saving pages:', error);
        });
    }
  };
  const deletePage = async (pageId: string) => {
    try {
      const response = await fetch(`${newServer}landing-page/${pageId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Eliminar la página del estado del frontend
        setPages(pages.filter((page) => page.id !== pageId));

        // Cambiar a la primera página disponible si hay más páginas
        if (pages.length > 1) {
          setSelectedPageId(pages[0].id); // Selecciona la primera página si la eliminada era la actual
        } else {
          setSelectedPageId(null); // Si no hay más páginas, resetea el estado
        }

        console.log('Page deleted successfully from backend');
      } else {
        console.error('Error deleting the page from the backend');
      }
    } catch (error) {
      console.error('Error occurred while deleting the page:', error);
    }
  };

  return (
    <div className='flex h-screen'>
      <div className='w-64 bg-gray-800 p-4'>
        <button
          className='w-full mb-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700'
          onClick={addPage}
        >
          Add New Page
        </button>
        <div className='space-y-2'>
          {pages.map((page) => (
            <div
              key={page.id}
              className={`flex justify-between items-center p-2 rounded cursor-pointer ${
                selectedPageId === page.id ? 'bg-gray-600' : 'bg-gray-700'
              }`}
              onClick={() => setSelectedPageId(page.id)}
            >
              <span className='text-white'>{page.name}</span>
              <IconButton
                onClick={(e) => handleMenuClick(e, page.id)}
                size='small'
                sx={{ color: 'white' }}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </div>

      {/* Main Editor */}
      <div className='flex-1'>
        <ThemeProvider theme={theme}>
          <GjsEditor
            grapesjs={grapesjs}
            plugins={[
              {
                id: 'gjs-blocks-basic',
                src: 'https://unpkg.com/grapesjs-blocks-basic',
              },
              {
                id: 'grapesjs-custom-code',
                src: 'https://unpkg.com/grapesjs-custom-code',
              },
              {
                id: 'grapesjs-plugin-forms',
                src: 'https://unpkg.com/grapesjs-plugin-forms',
              },
              {
                id: 'grapesjs-tailwind',
                src: 'https://unpkg.com/grapesjs-tailwind',
              },
              {
                id: 'grapesjs-links',
                src: 'https://unpkg.com/grapesjs-links',
              },
            ]}
            grapesjsCss='https://unpkg.com/grapesjs/dist/css/grapes.min.css'
            options={{
              container: '#gjs',
              fromElement: true,
              height: '100%',
              width: 'auto',
              canvas: {
                styles: ['https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css'],
              },
            }}
            onEditor={onEditor}
          />
        </ThemeProvider>
      </div>

      {/* Save Button */}
      <Box>
        <Button
          className='btn btn-primary bg-green-600 text-white px-4 py-2 rounded'
          onClick={saveEdition}
        >
          Save Edition
        </Button>
      </Box>

      {/* Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleRenameClick(selectedPageId || '')}>Rename</MenuItem>
        <MenuItem onClick={() => handleDeleteClick(selectedPageId || '')}>Delete</MenuItem>
      </Menu>

      {/* Rename Modal */}
      <Dialog open={openRenameModal} onClose={() => setOpenRenameModal(false)}>
        <DialogTitle>Rename Page</DialogTitle>
        <DialogContent>
          <TextField
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRenameModal(false)}>Cancel</Button>
          <Button onClick={handleRename} color='primary'>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
