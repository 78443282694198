import { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Icon,
  IconButton,
  InputAdornment,
  SnackbarContent,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Email,
  Lock,
  FilterVintage,
  AccountCircle,
  Visibility,
  VisibilityOff,
  Close,
  Info,
} from '@mui/icons-material';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import InformationDialog from 'Dialogs/Information/Information';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import './styles.css';
import { ipayNet, newServer } from 'constants/constants';
import { useNavigate } from 'react-router-dom';
import { HomeConfig } from 'Config/home.config';
import { useLocation } from 'react-router-dom';
import HttpsIcon from '@mui/icons-material/Https';
const loginWith2FA = true;

const AuthForm = ({ isModal }: any) => {
  const { setUser } = useLogin();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [_2fa, set2fa] = useState();
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const location = useLocation();

  /**
   * Handlers Functions
   */
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleForgotPasswordOpen = async () => {
    const subdomain = '';
    openDialog('reset-password', InformationDialog as any, {
      title: 'Reset Password',
      description:
        'Please enter the email address associated with your account and We will email you a link to reset your password.',
      icon: <HttpsIcon />,
      input: forgotPasswordEmail,
      setInput: (value: string) => setForgotPasswordEmail(value as any),
      inputPlaceholder: 'Enter email',
      cancelText: 'Cancel',
      onConfirm: (value: string) => handlePasswordReset(value, subdomain),
    });
  };

  const showModal = () => {
    openDialog('information', InformationDialog as any, {
      title: 'Login Completed',
      description: 'User created successfully',
      icon: <Info />,
    });
  };

  const showModal2fa = () => {
    openDialog('information', InformationDialog as any, {
      title: 'Verify 2FA (Two Factor Authentication)  Code',
      description:
        'To complete the login process, kindly verify the two-factor authentication code sent to your registered email or phone number. Please enter the code in the below field.',
      icon: <Info />,
      input: _2fa,
      setInput: set2fa,
      inputPlaceholder: '2FA code',
      cancelText: 'Cancel',
      onConfirm: validate2FA,
    });
  };

  const validate2FA = async (code: string) => {
    if (code) {
      try {
        const res = await fetch(`${newServer}/login/2FA`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: code,
            remember_me: false,
          }),
        });

        if (res.status === 200) {
          const data = await res.json();

          if (data?.token && data.token.error === undefined) {
            setUser({ email, token: data.token, user_id: data?.user.id, uuid: data?.user.uuid });
          } else {
            setAlertMessage(`Login failed. ${data?.token.info.message}.  Please try again.`);
            setSnackbarMessage('Login failed. Please try again.');
            setOpenSnackbar(true);
          }
          return;
        }

        setAlertMessage(await res.text());
      } catch (error) {
        console.log('validate2FA', error);
      }
    }
  };

  const getDomain = async () => {
    try {
      const response = await fetch(`${newServer}subdomain/get-domain-by-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.text();
      return data;
    } catch (error) {
      console.log('getDomain', error);
    }
  };

  const handleLogin = async () => {
    const subdomain = await getDomain();
    const user = { email, password, subdomain: subdomain };
    setLoading(true);
    setAlertMessage('');

    fetch(`${newServer}login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ipayNet.apiToken,
      },
      body: JSON.stringify(user),
    })
      .then(async (response) => {
        setLoading(false);
        if (response.status === 429) {
          // const { message } = await response.json();
          setSnackbarMessage(
            `Error: We've noticed some unusual activity coming from your IP address, so we've put a temporary pause on access as a safety measure. Don't worry, it's just a timeout! Give it a little while and try again. Need help sooner? Our support team is here for you.`
          );
          setOpenSnackbar(true);
          return;
        }
        if (response.status === 200) {
          if (loginWith2FA && (await response.text()) === 'OK') {
            showModal2fa();
            return;
          }

          const data = await response.json();

          if (data?.token && data.token.error === undefined) {
            setUser({ email, token: data.token, user_id: data?.user_id, uuid: data?.uuid });
          } else {
            setAlertMessage(`Login failed. ${data?.token.info.message}.  Please try again.`);
            setSnackbarMessage('Login failed. Please try again.');
            setOpenSnackbar(true);
          }
        } else {
          const textMessage = await response.text();
          setAlertMessage(`Login failed. ${textMessage}.  Please try again.`);
          setSnackbarMessage('Login failed. Please try again.');
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('handleLogin', error);
        setAlertMessage(`Login failed.`);
        setSnackbarMessage('Login failed. Please try again.');
        setOpenSnackbar(true);
      });
  };

  const handleRegister = () => {
    const user = { email, password, subDomain: 'subdominio1', subDomainName: 'Shelter 1' };
    setLoading(true);
    setAlertMessage('');

    fetch(`${newServer}users/simple`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ipayNet.apiToken,
      },
      body: JSON.stringify(user),
    })
      .then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          if (loginWith2FA && (await response.text()) === 'OK') {
            showModal2fa();
            return;
          }

          const data = await response.json();

          if (data?.token) {
            showModal();
            setUser({ email, token: data.token, user_id: data?.user_id, uuid: data?.uuid });
          }
        } else {
          const textMessage = await response.text();
          console.error('Registration failed', textMessage);
          setAlertMessage(`Registration failed. ${textMessage}.  Please try again.`);
          setSnackbarMessage('Registration failed. Please try again.');
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('handleRegister', error);
        setAlertMessage(`Registration failed. ${error}`);
        setSnackbarMessage('Registration failed. Please try again.');
        setOpenSnackbar(true);
      });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordReset = async (email: string, subdomain: any) => {
    try {
      const res = await fetch(`${newServer}password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, subdomain }),
      });
      if (res.status === 200) {
        setSnackbarMessage('Password reset link sent to your email!');
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage('Error resetting password. Please try again.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage('Error resetting password. Please try again.');
      setOpenSnackbar(true);
    }
  };

  return (
    <Card
      style={{
        borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
        padding: '20px',
        minWidth: '300px',
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '20px',
      }}
    >
      {alertMessage && <Alert severity='error'>{alertMessage}</Alert>}
      <CardContent className='card-content'>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust as needed
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            message={<span>{snackbarMessage}</span>}
            action={
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleSnackbarClose}
              >
                <Icon fontSize='small'>
                  <Close />
                </Icon>
              </IconButton>
            }
          />
        </Snackbar>

        <Typography variant='h5' align='center' gutterBottom>
          <Icon style={{ color: '#ff0099', fontSize: '36px', marginTop: '10px' }}>
            <FilterVintage />
          </Icon>
          Registration Required
        </Typography>
        <Typography variant='body1' align='center' color='textSecondary' paragraph>
          Already a {HomeConfig.merchatName} Platform partner ?{' '}
        </Typography>
        <TextField
          label='Email'
          variant='outlined'
          type='email'
          fullWidth
          margin='normal'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <Icon style={{ color: '#ff0099' }}>
                <Email />
              </Icon>
            ),
            style: {
              borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
            },
          }}
        />
        <TextField
          label='Password'
          variant='outlined'
          fullWidth
          margin='normal'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: (
              <Icon style={{ color: '#ff0099' }}>
                <Lock />
              </Icon>
            ),
            style: {
              borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={handlePasswordVisibility}
                  edge='end'
                  color='primary'
                  aria-label='toggle password visibility'
                >
                  {showPassword ? (
                    <Visibility style={{ color: '#ff0099' }} />
                  ) : (
                    <VisibilityOff style={{ color: '#ff0099' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleLogin}
            disabled={email.length === 0 || loading}
            style={{
              backgroundColor: '#ff0099',
              color: '#fff',
              marginRight: '10px',
              cursor: 'pointer',
              width: '500px',
              height: '50px',
              borderRadius: '15px',
            }}
          >
            {loading && <CircularProgress size={24} color='inherit' />}
            <Icon style={{ color: '#fff', marginRight: '5px', marginBottom: '5px' }}>
              <Lock />
            </Icon>
            Login
          </Button>

          {location.pathname !== '/backoffice' && (
            <Button
              variant='outlined'
              color='primary'
              disabled={email.length === 0 || loading}
              onClick={handleRegister}
              style={{
                borderColor: '#ff0099',
                color: '#ff0099',
                cursor: 'pointer',
                width: '500px',
                height: '50px',
                borderRadius: '15px',
              }}
            >
              {loading && <CircularProgress size={24} color='inherit' />}
              <Icon
                style={{
                  color: '#ff0099',
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
              >
                <AccountCircle />
              </Icon>
              Register
            </Button>
          )}
        </div>

        <Typography
          variant='body2'
          align='center'
          color='textSecondary'
          style={{ cursor: 'pointer', marginTop: '15px' }}
          onClick={handleForgotPasswordOpen}
        >
          <a className='link'>Forgot Password</a>
        </Typography>

        <Typography
          variant='body2'
          align='center'
          color='textSecondary'
          style={{ marginTop: '30px' }}
        >
          By registering you accept our {/* // Todo: add here privacy links */}
          <a className='link' onClick={() => navigate('/terms')}>
            {' '}
            Terms and Conditions
          </a>{' '}
          and our{' '}
          <a className='link' onClick={() => navigate('/policies')}>
            Privacy Policy
          </a>
          .
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AuthForm;
